import React from 'react'
import { PiEnvelopeSimpleOpenLight, PiLinkedinLogo, PiTelegramLogo } from 'react-icons/pi'

const Footer = () => {
  return (
    <footer className='w-full h-full md:h-32 border-t border-sky-900/25'>
      <div className='w-full md:w-10/12  m-auto p-6 py-6 md:p-10'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className='text-start md:text-center'>
            <h1 className='text-gray-400'>© {new Date().getFullYear()} <span className='capitalize text-gray-800'>michal jirák</span></h1>
          </div>
          <div className='flex flex-row items-center gap-4 text-lg text-black leading-none mt-4 md:mt-0'>
            <a href="mailto:jirak5@seznam.cz?subject=Webová poptávka" rel="noreferrer" target='_blank'>
              <div className='socials'>
                <PiEnvelopeSimpleOpenLight className='w-6 h-6' />
              </div>
            </a>
            <a href="https://t.me/michaljirak" rel="noreferrer" target='_blank'>
              <div className='socials'>
                <PiTelegramLogo className='w-6 h-6' />
              </div>
            </a>
            <a href="https://www.linkedin.com/in/michal-jir%C3%A1k-572b0a162/" rel="noreferrer" target='_blank'>
              <div className='socials'>
                <PiLinkedinLogo className='w-6 h-6' />
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* 
      <div>
        <BackToTop />
      </div>
      */}
    </footer>
  )
}

export default Footer

// react + dependencies
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
// components
import ContactForm from "../components/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageVisit",
        pageName: "kontakt",
      },
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Kontakt - Michal Jirák - tvorba a redesign webů</title>
        <meta
          property="og:title"
          content="Kontakt - Michal Jirák - tvorba a redesign webů"
        />
        <meta
          property="og:description"
          content="Stránka se všemi kontaktními údaji, přehledně na jednom místě. Chcete-li si nechat zpracovat nezávaznou nabídku nebo máte jen dotaz, nebojte se mi napsat."
        />
        <meta
          name="description"
          content="Stránka se všemi kontaktními údaji, přehledně na jednom místě. Chcete-li si nechat zpracovat nezávaznou nabídku nebo máte jen dotaz, nebojte se mi napsat"
        />
      </Helmet>
      <section className="w-full pb-16 md:pb-32" id="kontakt">
        <div className="container mx-auto ">
          <div className="text-center">
            <div className="h-[20vh] flex flex-col items-center justify-center">
              <h3 className="uppercase text-4xl md:text-5xl font-bold mb-2 tracking-widest">
                kontakt
              </h3>
            </div>
            <div className="p-6 md:p-0 animate__animated animate__fadeInUp animate__fast">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

// react + dependencies
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  PiEnvelopeSimpleOpenLight,
  PiLinkedinLogo,
  PiTelegramLogo,
} from "react-icons/pi";

const Navbar = () => {
  // useState for mobile menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // scroll to top - used on link clicks
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    // Add or remove "overflow: hidden" on the body element when mobile menu is open/closed
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileMenuOpen]);

  return (
    <>
      <header className="w-full">
        <nav className="container m-auto py-10 px-6 flex flex-row justify-between items-center capitalize text-gray-500 ">
          <div className="text-start relative z-50">
            <a href="/">
              <div
                className="flex flex-row items-end md:items-center gap-3 md:hover:underline-offset-8 hover:underline"
                title="Zpět na úvod s refreshem stránky"
              >
                <img
                  src="../img/profile/profilovkaPNG.webp"
                  className="w-6 md:w-12 rounded-full rotate-12 md:hover:scale-110 md:transition-all"
                  alt="Michal Jirák"
                />
                <h1 className="text-black text-lg">michal jirák</h1>
              </div>
            </a>
          </div>
          <div className="hidden md:flex flex-row gap-6 text-black text-lg">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              Home
            </NavLink>
            <NavLink
              to="/projekty"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              projekty
            </NavLink>
            <NavLink
              to="/kontakt"
              className={({ isActive }) =>
                `px-2 py-1 rounded-lg ${isActive ? "active" : ""}`
              }
              onClick={scrollToTop}
            >
              kontakt
            </NavLink>
          </div>
          <div className="md:hidden flex items-center order-3 md:order-2">
            <button
              onClick={toggleMobileMenu}
              className="focus:outline-none outline-none border-none text-black bg-transparent z-50"
              aria-label="Mobile Menu Toggle"
            >
              <div className={`menu-icon ${mobileMenuOpen ? "open" : ""}`}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </button>
          </div>
          <div className="hidden md:flex flex-row items-center order-2 md:order-3 gap-4 text-lg text-black leading-none">
            <a
              href="mailto:jirak5@seznam.cz?subject=Webová poptávka"
              rel="noreferrer"
              target="_blank"
            >
              <div className="socials" title="Pošlete mi email">
                <PiEnvelopeSimpleOpenLight className="w-6 h-6" />
              </div>
            </a>
            <a href="https://t.me/michaljirak" rel="noreferrer" target="_blank">
              <div className="socials" title="Napište mi přes Telegram">
                <PiTelegramLogo className="w-6 h-6" />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/michal-jir%C3%A1k-572b0a162/"
              rel="noreferrer"
              target="_blank"
            >
              <div className="socials" title="Můj LinkedIn">
                <PiLinkedinLogo className="w-6 h-6" />
              </div>
            </a>
          </div>
        </nav>

        {/* mobile menu opened */}
        {mobileMenuOpen && (
          <div className="w-full h-screen fixed top-0 bg-slate-200 flex flex-col justify-center items-center overflow-hidden z-40">
            {/* mobile navigation */}
            <div className="h-full flex flex-col items-center justify-evenly text-2xl">
              <div className="flex flex-col items-center gap-10">
                <NavLink
                  to="/"
                  exact
                  onClick={() => {
                    scrollToTop();
                    toggleMobileMenu();
                  }}
                >
                  úvod
                </NavLink>
                <NavLink
                  to="/projekty"
                  onClick={() => {
                    scrollToTop();
                    toggleMobileMenu();
                  }}
                >
                  projekty
                </NavLink>
                <NavLink
                  to="/kontakt"
                  onClick={() => {
                    scrollToTop();
                    toggleMobileMenu();
                  }}
                >
                  kontakt
                </NavLink>
              </div>

              {/* mobile socials */}
              <div className="flex flex-row gap-10">
                <a
                  href="mailto:jirak5@seznam.cz?subject=Webová poptávka"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="socials">
                    <PiEnvelopeSimpleOpenLight className="w-8 h-8" />
                  </div>
                </a>
                <a
                  href="https://t.me/michaljirak"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="socials">
                    <PiTelegramLogo className="w-8 h-8" />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/in/michal-jir%C3%A1k-572b0a162/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="socials">
                    <PiLinkedinLogo className="w-8 h-8" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Navbar;

// react + dependencies
import { Navigate, Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";
import 'animate.css';
// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Detail from "./components/Detail";

const tagManagerArgs = {
  gtmId: "GTM-5BQMMKV",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projekty" element={<Projects />} />
        <Route path="/projekty/:urlID" element={<Detail />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

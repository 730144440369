import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { fetchProjects } from "../api/projects";
import Skeleton from "react-loading-skeleton";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [imageLoadingStates, setImageLoadingStates] = useState([]);

  useEffect(() => {
    // Trigger event when the component mounts
    TagManager.dataLayer({
      dataLayer: {
        event: "pageVisit",
        pageName: "projekty",
      },
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch the projects
      const data = await fetchProjects();
      setProjects(data);

      // Initialize image loading states
      setImageLoadingStates(new Array(data.length).fill(true));
    };

    fetchData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Handle image load for a specific index
  const handleImageLoad = (index) => {
    setImageLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false; // Set the specific image loading state to false
      return newStates;
    });
  };

  return (
    <>
      <Helmet>
        <title>Projekty - Michal Jirák - tvorba a redesign webů</title>
        <meta
          property="og:title"
          content="Projekty - Michal Jirák - tvorba a redesign webů"
        />
        <meta
          property="og:description"
          content="Podívejte se na mé projekty, na kterých jsem v minulosti pracoval. Třeba si vyberete takový, který by lépe odpovídal Vašim představám."
        />
        <meta
          name="description"
          content="Podívejte se na mé projekty, na kterých jsem v minulosti pracoval. Třeba si vyberete takový, který by lépe odpovídal Vašim představám"
        />
      </Helmet>
      <section className="w-full pb-16 md:pb-32" id="projects">
        <div className="container mx-auto">
          <div className="text-center w-10/12 lg:w-full m-auto">
            <div className="h-[20vh] flex flex-col items-center justify-center">
              <h3 className="uppercase text-4xl md:text-5xl font-bold mb-2 tracking-widest">
                moje projekty
              </h3>
            </div>
            <div className="w-full lg:w-9/12 m-auto pt-6 md:pt-0 animate__animated animate__fadeInUp animate__fast">
              <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
                {projects.map((project, index) => (
                  <div
                    key={project.id}
                    className="bg-white/30 hover:bg-white/50 text-black transition-all p-0 shadow-lg hover:shadow-2xl w-full rounded-lg hover:cursor-pointer"
                  >
                    <div className="hidden">
                      {/* HIDDEN FOR NOW: absolute flex flex-row flex-wrap gap-1 */}
                      {project.type &&
                        project.type.map((type, index) => (
                          <div key={index} className={type}></div>
                        ))}
                    </div>
                    <div className="p-0 shadow-md">
                      <NavLink to={`/projekty/${project.urlID}`}>
                        {project.detailimageUrl ? (
                          <>
                            {imageLoadingStates[index] && (
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  className="w-8 h-8 animate-spin dark:text-gray-600 fill-white"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span className="sr-only">
                                  Načítám obrázek...
                                </span>
                              </div>
                            )}
                            <img
                              src={project.imageUrl}
                              alt={project.name}
                              className={`rounded-t-lg shadow-xl w-full h-auto ${
                                imageLoadingStates[index] ? "hidden" : ""
                              }`}
                              onLoad={() => handleImageLoad(index)}
                            />
                          </>
                        ) : (
                          <Skeleton width="100%" height="auto" />
                        )}
                      </NavLink>
                    </div>
                    <div className="p-6 ">
                      <h5 className="uppercase font-semibold text-lg">
                        {project.name}
                      </h5>
                      <p className="font-light">{project.description}</p>
                      <div>
                        <NavLink to={`/projekty/${project.urlID}`}>
                          <button className="btn-primary" onClick={scrollToTop}>
                            detail realizace
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
